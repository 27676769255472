import { createContext } from "react";

const ThemeContext = createContext(false);
// export const themes = {
//     light: {
//       main: "#000000",
//       secondary: "#eeeeee",
//       accent:""
//     },
//     dark: {
//       main: "#ffffff",
//       secondary: "#222222",
//       accent:""
//     },
//   };

// export const DarkModeEnabled=false;

export default ThemeContext;
